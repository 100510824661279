.l-login {
	background-color: $dark-grey;
	width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&--content {
		max-width: 500px;
		width: 100%;
		margin: 0 auto;
		padding: $padding-rythm $padding-rythm-sm*2;
		height: auto;
		position: relative;
	}

	&--title {
		font-size: 30px !important;
		color: white !important;
		font-family: $condensed-font;
		text-align: center;
		display: block;
		margin-bottom: $padding-rythm-sm !important;
	}

	&--text {
		color: $middle-grey;
		text-align: center;
		display: block;
		font-size: $fs-body-sm;

		&-link {
			text-decoration: underline;
			color: white;
			display: inline-block;

			&:hover,
			&:focus {
				color: $middle-grey;
				cursor: pointer;
			}
		}
	}

	&--submit {
		color: white;
		border: 1px solid $blue;
		background-color: $blue;
		border-radius: 2px;
		box-shadow: $shadow;
		text-transform: uppercase;
		font-family: $condensed-font;
		text-align: center;
		width: auto;
		display: block;
		padding: $padding-rythm-sm/2;
		width: 100%;
		appearance: none;
		transition: background-color 0.2s ease, color 0.2s ease;
		margin-top: $padding-rythm;

		&:focus,
		&:hover,
		&:active {
			background-color: white;
			color: $blue;
			transition: background-color 0.2s ease, color 0.2s ease;
		}
	}

	&--icon-note {
		color: $middle-grey;
		text-align: left;
		margin: 0;
		vertical-align: middle;
		display: flex;
		justify-content: center;
		font-size: $fs-body-sm;

		& > svg {
			min-width: 18px;
			height: 18px;
			stroke: $middle-grey;
			margin-right: $padding-rythm-sm;
		}
	}

	&--terms {
		color: white;
		text-align: center;
		display: inline-block;
		width: 100%;
		margin: $padding-rythm-sm auto 0 auto;
		font-family: $font;

		& > svg {
			min-width: 16px;
			height: 16px;
			fill: white;
		}
	}

	&--popup {
		display: block;
		position: absolute;
		top: 30%;
		background: white;
		color: $blue;
		margin: $padding-rythm-sm*2 $padding-rythm-sm;
		padding: $padding-rythm-sm;
		box-shadow: $shadow;
		border-radius: 2px;
		left: 0;
		width: calc(100% - #{$padding-rythm-sm * 2});
		text-align: center;
		font-size: $fs-body-sm;
		opacity: 0;
		transform: translateX(100vw);
		transition: opacity 0.3s ease, transform 0.2s step-end;

		&-title {
			margin-bottom: $padding-rythm-sm;
			font-family: $condensed-font;
			text-transform: uppercase;
		}

		&-close {
			position: absolute;
			width: 16px;
			height: 16px;
			transform: rotate(45deg);
			right: $padding-rythm-sm;
			top: $padding-rythm-sm;

			&:hover {
				cursor: pointer;
			}
		}

		&.is-active {
			opacity: 1;
			transform: translateX(0);
			transition: opacity 0.3s ease, transform 0.2s step-start;
		}
	}
}

@include from($bp-medium) {
	.l-login {
		width: 100vw;
		height: 100vh;

		&--content {
			padding: 0;
			margin: $padding-rythm auto;
		}

		&--title {
			font-size: 44px;
		}

		&--text {
			font-size: $fs-body;
		}

		&--form {
			margin: 0 $padding-rythm-sm/2;
		}

		&--terms {
			& > svg {
				display: none;
			}
		}

		&--popup {
			margin: 0;
			width: 100%;
			font-size: $fs-body;
			padding: $padding-rythm-sm*2;

			&-title {
				margin-bottom: $padding-rythm-sm*2;
			}
		}

		&--icon-note {
			text-align: center;
			font-size: $fs-body;
			& > svg {
				height: 18px;
			}
		}
	}
}
