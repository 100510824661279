@import "config";
@import "mixins/mixin-loader";

@import "components/hidden-form";
@import "layouts/login";

.button--icon.text-replace.button-small {
    width: 1.8rem;
    height: 1.8rem;
}
