.hidden-form {
	display: none;
	& > label {
		color: white;
		font-family: $condensed-font;
		letter-spacing: 0.01em;
	}
	& > button {
		flex-grow: 2;
		padding: 12px $padding-rythm-sm 9px $padding-rythm-sm;
		color: white;
		background-color: $blue;
		text-transform: uppercase;
		text-align: center;
		font-family: $condensed-font;
		letter-spacing: 0.01em;
		border: $blue;
		margin: $padding-rythm-sm 0;
	}

	&.is-visible {
		display: block;
	}

	&--opener {
		width: 0;
		height: 0;
		opacity: 0;
		pointer-events: none;

		&:focus {
			width: 10px;
			height: 10px;
			opacity: 1;
			background-color: red;
		}
	}
}
